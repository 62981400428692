<template>
         <div class="MBtextcont">
            <p :class="pNoMargin?'nomargin':''"><slot></slot></p>
            <ul v-if="liItems">
                <li v-for="(item, index) in liItems" :key="index">
                    {{item}}
                </li>
            </ul>
        </div>
        <div class="KImage" v-if="imgSrc"><KImage :src="imgSrc" alt="" width="100%"/></div>
   
</template>
<script>
import KImage from '../../../Widgets/KImage'
export default {
    name: "Paragraph",
    components: {
        KImage
    },
    props: {
        pNoMargin: Boolean,
        liItems: {
            type: Array,
            default: null
        },
        imgSrc: {
            type: String,
            default: null
        }
    }
}
</script>
<style scoped>
    .KImage{ 
        width: 80%;
        margin: 0 auto 50px auto;
        display: block;
    }
</style>